import { useStore } from "vuex";
import { computed } from "vue";

const exploreAPI = () => {
  const store = useStore();

  const displayEditor = async (params) => {
    return await store.dispatch("explore/displayEditor", params);
  };

  const getProject = async (params) => {
    return await store.dispatch("explore/getProject", params);
  };

  const getExploreExtras = async (params) => {
    return await store.dispatch("explore/getExploreExtras", params);
  };

  const getAllReports = async (params) => {
    return await store.dispatch("explore/getAllReports", params);
  };


  const getInteraction = async (params) => {
    return await store.dispatch("explore/getInteraction", params);
  };

  const createPost = async (params) => {
    return await store.dispatch("explore/createPost", params);
  };

  const createUpvote = async (params) => {
    return await store.dispatch("explore/createUpvote", params);
  };

  const createPrevote = async (params) => {
    return await store.dispatch("explore/createPrevote", params);
  };

  const deleteUpvote = async (params) => {
    return await store.dispatch("explore/deleteUpvote", params);
  };

  const getUserPosts = async (params) => {
    return await store.dispatch("explore/getUserPosts", params);
  };

  const getTimeline = async (params) => {
    return await store.dispatch("explore/getTimeline", params);
  };

  const getCurrentAudit = async (params) => {
    return await store.dispatch("explore/getCurrentAudit", params);
  };

  const saveLocalPost = async (params) => {
    return await store.dispatch("explore/saveLocalPost", params);
  };

  const getDyorReports = async (params) => {
    return await store.dispatch("explore/getDyorReports", params);
  };

  const userAuditInfo = async (params) => {
    return await store.dispatch("explore/userAuditInfo", params);
  };

  const createBull = async (params) => {
    return await store.dispatch("explore/createBull", params);
  };

  const deleteBull = async (params) => {
    return await store.dispatch("explore/deleteBull", params);
  };

  const createBear = async (params) => {
    return await store.dispatch("explore/createBear", params);
  };

  const deleteBear = async (params) => {
    return await store.dispatch("explore/deleteBear", params);
  };

  const sleep = (timeInMs) => {
    return new Promise((resolve) => setTimeout(() => resolve(false), timeInMs));
  };
  
  return {
    getProject,
    getterCurrentProject: computed(
      () => store.getters["explore/getterCurrentProject"]
    ),
    getterLastPost: computed(() => store.getters["explore/getterLastPost"]),
    getterUserPosts: computed(() => store.getters["explore/getterUserPosts"]),
    getterUserReplies: computed(() => store.getters["explore/getterUserReplies"]),
    getterUserComments: computed(
      () => store.getters["explore/getterUserComments"]
    ),
    getterSelectedPost: computed(
      () => store.getters["explore/getterSelectedPost"]
    ),
    getterCurrentAudit: computed(
      () => store.getters["explore/getterCurrentAudit"]
    ),
    getterExploreExtras: computed(
      () => store.getters["explore/getterExploreExtras"]
    ),
    getterDisplayEditor: computed(
      () => store.getters["explore/getterDisplayEditor"]
    ),
    getterInteraction: computed(
      () => store.getters["explore/getterInteraction"]
    ),
    getterUserAuditInfo: computed(
      () => store.getters["explore/getterUserAuditInfo"]
    ),
    getterDyorReports: computed(
      () => store.getters["explore/getterDyorReports"]
    ),
    getterAllReports: computed(
      () => store.getters["explore/getterAllReports"]
    ),
    getInteraction,
    userAuditInfo,
    createPost,
    createUpvote,
    displayEditor,
    getAllReports,
    deleteUpvote,
    sleep,
    getUserPosts,
    saveLocalPost,
    getDyorReports,
    getCurrentAudit,
    createPrevote,
    getExploreExtras,
    createBull,
    deleteBull,
    createBear,
    deleteBear,
    getTimeline,
  };
};

export default exploreAPI;
