const signInMutation = (state, { pid, email, username, avatar }) => {
  state.user = { pid, email, username, avatar };
  state.status = "authenticated";
};

const signOutMutation = (state) => {
  state.user = {
    pid: "000",
    username: "Username",
    avatar: "https://space.auditocean.com/user-avatar%2F1-1.svg",
  };
  state.status = "not-authenticated";
};

const recovery = (state, { success }) => {
  console.info(success, "RECOVERY");
};

const currentUserMutation = (state, { pid, email, username, avatar }) => {
  state.user = { pid, email, username, avatar };
  state.status = "authenticated";
};

export { signInMutation, signOutMutation, recovery, currentUserMutation };
