const authStatus = (state) => {
  return state.status;
};

const currentUser = (state) => {
  return state.user;
};

const getterCurrentUser = (state) => {
  return state.user;
};

const getterUsername = (state) => {
  return state.user.username;
};
export { getterCurrentUser, authStatus, currentUser, getterUsername };
