export default () => ({
  last_query: null,
  user_posts: [],
  user_comments: [],
  user_replies: [],
  last_post:  null,
  current_interaction: {
    pid: null,
    post_count: 0,
    audit_count: 0,
    bull_count: 0,
    bear_count: 0,
    user_bull: 0,
    user_bear: 0,
  },
  all_reports: [
    {
      pid: "",
      round: "",
      group: "",
      score: "",
      percentage: "",
      date: ""
    },
  ],
  current_audit: null,
  explore_extras: {
    related: [],
    tags: [],
  },
  display_editor: false,
  user_audit_info: {
    round: {
      round: "N/A",
      status: "N/A",
      deadline: "N/A",
    },
    button: {
      locked: true,
      icon: "pi pi-lock",
      label: "",
    },
    pre_vote: null,
    dao_vote: null,
  },
  community_reports: [
    {
      id: "",
      percentage: "",
      score: "",
      author: "",
      date: "",
    },
  ],
  current_project: {
    logo_url: {
      base: "",
      path: "",
      base64: "",
    },
    website: "",
    whitepaper: "",
    name: "",
    category: "N/A",
    description: "N/A",
    tags: ["N/A"],
    community: {
      reddit: "",
      telegram: "",
      instagram: "",
      youtube: "",
      discord: "",
      medium: "",
      facebook: "",
      twitter: "",
    },
  },
});
