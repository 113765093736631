import axiosApi from "@/api/axios-api";

// eslint-disable-next-line
const getRoundInformation = async ({ commit }) => {
  try {
    const response = await axiosApi.get("/api/audits/get-round-information");

    commit("getRoundInformationMutation", response.data);
    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

export { getRoundInformation };
