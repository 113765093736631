import axiosApi from "@/api/axios-api";

// eslint-disable-next-line
const getAuditReport = async ({ commit }, id) => {
  try {
    const response = await axiosApi.get(`/api/audits/get-audit-report/${id}`);

    const datum = response.data;

    const { base, base64 } = datum.project.logo_url;

    const base64_ = await fetch(base + base64).then((e)=> e.json());

    commit("getAuditReportBase64Mutation", base64_);
  
    commit("getAuditReportMutation", datum);
  } catch (error) {
    throw { ok: false, response: error };
  }
};

export { getAuditReport };
