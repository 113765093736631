const feedLazyTimelineCommit = (state, data) => {
  state.feedLazyTimeline = data;
};

const feedOverlayCommit = (state, data) => {
  state.feedOverlay = data;
};

const feedPostCommit = (state, data) => {
  state.feedPost = data;
};

const cleanFeedPostCommit = (state) => {
  state.feedPost = null;
};

const getInteractionsCommit = (state, data) => {
  state.editorData = data;
  state.feedEditorChannel = data.default;
};

const changeEditorChannelCommit = (state, data) => {
  state.feedEditorChannel = data;
};

export {
  changeEditorChannelCommit,
  feedLazyTimelineCommit,
  getInteractionsCommit,
  feedOverlayCommit,
  feedPostCommit,
  cleanFeedPostCommit,
};
