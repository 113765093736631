const getterCurrentProject = (state) => {
  return state.current_project;
};

const getterExploreExtras = (state) => {
  return state.explore_extras;
};

const getterDyorReports = (state) => {
  return state.community_reports;
};

const getterAllReports = (state) => {
  return state.all_reports;
};

const getterLastPost = (state) => {
  return state.last_post;
};

const getterUserPosts = (state) => {
  return state.user_posts;
};

const getterUserComments = (state) => {
  return state.user_comments;
};

const getterUserAuditInfo = (state) => {
  return state.user_audit_info;
};

const getterCurrentAudit = (state) => {
  return state.current_audit;
};

const getterDisplayEditor = (state) => {
  return state.display_editor;
};

const getterInteraction = (state) => {
  return state.current_interaction;
};

const getterUserReplies = (state) => {
  return state.user_replies;
};

export {
  getterInteraction,
  getterUserReplies,
  getterUserAuditInfo,
  getterDisplayEditor,
  getterUserPosts,
  getterCurrentProject,
  getterAllReports,
  getterCurrentAudit,
  getterLastPost,
  getterExploreExtras,
  getterUserComments,
  getterDyorReports,
};
