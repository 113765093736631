import authApi from "@/api/axios-api";

// eslint-disable-next-line
export const verify = async ({ commit }, token) => {
  try {
    await authApi.get(`/api/users/verify-email/${token}`);

    return { ok: true, message: "success" };
  } catch (error) {
    return { ok: false, message: error.response.data.errors };
  }
};
