import { GraphQLClient } from "graphql-request";
import { env } from './index.js';

const host = {
  dev: 'https://auditocean.dev/api/query',
  prod: 'https://auditocean.com/api/query'
}

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Credentials": true,
};

const queryApi = new GraphQLClient(host[env], {
  credentials: "include",
  headers,
});

export default queryApi;
