import { useStore } from "vuex";
import { computed } from "vue";

const entryAPI = () => {
  const store = useStore();

  const signUp = async (user) => {
    return await store.dispatch("entry/signUp", user);
  };

  const signIn = async (user) => {
    return await store.dispatch("entry/signIn", user);
  };

  const signOut = async (user) => {
    return await store.dispatch("entry/signOut", user);
  };

  const recovery = async (user) => {
    return await store.dispatch("entry/recovery", user);
  };

  const currentUser = async () => {
    return await store.dispatch("entry/currentUser");
  };

  const resendEmail = async (user) => {
    return await store.dispatch("entry/resendEmail", user);
  };

  return {
    signUp,
    signIn,
    signOut,
    recovery,
    currentUser,
    getterUser: computed(() => store.getters["entry/currentUser"]),
    getterCurrentUser: computed(() => store.getters["entry/getterCurrentUser"]),
    resendEmail,
    authStatus: computed(() => store.getters["entry/authStatus"]),
    getterUsername: computed(() => store.getters["entry/getterUsername"]),
  };
};

export default entryAPI;
