const fakeReport = {
  _id: "",
  version: "VERSION",
  round: "R0",
  round_pid: "ROUND_PID",
  group_pid: "GROUP_PID",
  auditor_pid: "AUDITOR_PID",
  project: {
    pid: "PROJECT_PID",
    name: "PROJECT_NAME",
    category: "PROJECT_CATEGORY",
    funded: "PROJECT_FUNDED",
    listed: "PROJECT_LISTED",
    audited: "PROJECT_AUDITED",
    symbol: "PROJECT_SYMBOL",
    description: "PROJECT_DESCRIPTION",
    logo_url: {
      base: "PROJECT_LOGO_BASE",
      path: "PROJECT_LOGO_PATH",
      base64: "PROJECT_LOGO_BASE64",
    },
    repository: {
      name: "PROJECT_REPOSITORY",
      link: "PROJECT_REPOSITORY",
    },
    whitepaper: "PROJECT_WHITEPAPER",
    website: "PROJECT_WEBSITE",
    community: {
      reddit: "PROJECT_COMMUNITY_REDDIT",
      telegram: "PROJECT_COMMUNITY_TELEGRAM",
      instagram: "PROJECT_COMMUNITY_INSTAGRAM",
      youtube: "PROJECT_COMMUNITY_YOUTUBE",
      discord: "PROJECT_COMMUNITY_DISCORD",
      medium: "PROJECT_COMMUNITY_MEDIUM",
      facebook: "PROJECT_COMMUNITY_FACEBOOK",
      twitter: "PROJECT_COMMUNITY_TWITTER",
    },
    tags: ["PROJECT_TAG"],
    _id: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    __v: 0,
  },
  category_score: {
    development_team: 0.0,
    tokenomics: 0.0,
    community: 0.0,
  },
  category_max_score: {
    development_team: 0.0,
    tokenomics: 0.0,
    community: 0.0,
  },
  total_score: 0.0,
  total_max_score: 0.0,
  total_percentage: "00.00",
  category_scheme: {
    development_team: [
      {
        id: 0,
        tag: "DT1-0",
        score: 0,
        max_score: 0,
        question: "Are you Affiliated with the project or own any tokens?",
        answer_id: 2,
        answer_name: "No",
        name: "Conflict of Interest Disclosure",
        category: "development_team",
      },
      {
        id: 1,
        tag: "DT2-1",
        score: 0,
        max_score: 0,
        question: "Did you read the whitepaper?",
        answer_id: 1,
        answer_name: "Yes",
        name: "Read Whitepaper",
        category: "development_team",
      },
      {
        id: 2,
        tag: "DT3-2",
        score: 0,
        max_score: 4,
        question: "Is the development team anonymous?",
        answer_id: 1,
        answer_name: "Yes",
        name: "Anonymous Team",
        category: "development_team",
      },
      {
        id: 3,
        tag: "DT4-3",
        score: 0,
        max_score: 2,
        question:
          "Can you verify that each member of the Development team is a real person?",
        answer_id: 2,
        answer_name: "No",
        name: "Identity Verification",
        category: "development_team",
      },
      {
        id: 4,
        tag: "DT5-4",
        score: 0.5,
        max_score: 0.5,
        question:
          "Does the Dev team have the proper experience to execute this project? ",
        answer_id: 1,
        answer_name: "Yes",
        name: "Prior Experience",
        category: "development_team",
      },
      {
        id: 5,
        tag: "DT6-5",
        score: 0,
        max_score: 1,
        question:
          "Has the project successfully completed an audit by a reputable service provider?",
        answer_id: 2,
        answer_name: "No",
        name: "Audit",
        category: "development_team",
      },
      {
        id: 6,
        tag: "DT7-6",
        score: 0,
        max_score: 1,
        question: "Was there any plagiarism in the whitepaper?",
        answer_id: 1,
        answer_name: "Yes",
        name: "Plagarism",
        category: "development_team",
      },
      {
        id: 7,
        tag: "DT8-7",
        score: 1,
        max_score: 1,
        question:
          "Are there grammar, spelling, and punctuation errors in the whitepaper or webpage?",
        answer_id: 2,
        answer_name: "No",
        name: "Spelling Errors",
        category: "development_team",
      },
      {
        id: 8,
        tag: "DT9-8",
        score: 0.2,
        max_score: 0.5,
        question: "What is the length of the Whitepaper?",
        answer_id: 2,
        answer_name: "6 to 15 pages",
        name: "Whitepaper Length",
        category: "development_team",
      },
    ],
    tokenomics: [
      {
        id: 9,
        tag: "TK1-9",
        score: 2,
        max_score: 3,
        question:
          "What portion of the tokens are held by the Development team or early investors?",
        answer_id: 2,
        answer_name: "16% to 25%",
        name: "Insider Allocation",
        category: "tokenomics",
      },
      {
        id: 10,
        tag: "TK2-10",
        score: 0.5,
        max_score: 1,
        question:
          "What portion of tokens will be available to the general public either through sale or rewards ?",
        answer_id: 2,
        answer_name: "50% to 75%",
        name: "Community Allocation",
        category: "tokenomics",
      },
      {
        id: 11,
        tag: "TK3-11",
        score: 0,
        max_score: 1.5,
        question:
          "For tokens reserved for development team and early investors, is there a vesting schedule?",
        answer_id: 2,
        answer_name: "No",
        name: "Vesting",
        category: "tokenomics",
      },
      {
        id: 12,
        tag: "TK4-12",
        score: 1.5,
        max_score: 1.5,
        question:
          "Is the token minting policy locked so that no changes can be made?",
        answer_id: 1,
        answer_name: "Yes",
        name: "Locked Policy",
        category: "tokenomics",
      },
      {
        id: 13,
        tag: "TK5-13",
        score: 2,
        max_score: 2,
        question:
          "Do you clearly understand the use case after reading the whitepaper and reviewing website info?",
        answer_id: 1,
        answer_name: "Yes",
        name: "Clear Use Case",
        category: "tokenomics",
      },
      {
        id: 14,
        tag: "TK6-14",
        score: 0,
        max_score: 0.5,
        question:
          "If the project utilizes an Initial Stake Offering, what is the percentage they charge?",
        answer_id: 2,
        answer_name: ">3%",
        name: "ISO Fee",
        category: "tokenomics",
      },
      {
        id: 15,
        tag: "TK7-15",
        score: 0.5,
        max_score: 0.5,
        question:
          "Is the project an already existing business model or the first of its kind?",
        answer_id: 2,
        answer_name: "New",
        name: "Business Model",
        category: "tokenomics",
      },
    ],
    community: [
      {
        id: 16,
        tag: "CT1-16",
        score: 0.5,
        max_score: 1,
        question: "How many followers does the project have on Twitter?",
        answer_id: 2,
        answer_name: "1000 to 5000",
        name: "Twitter",
        category: "community",
      },
      {
        id: 17,
        tag: "CT2-17",
        score: 2,
        max_score: 2,
        question:
          "For the twitter account, does it appear to have actual followers or bots/paid followers?",
        answer_id: 2,
        answer_name: "Real",
        name: "Twitter Real Followers",
        category: "community",
      },
      {
        id: 18,
        tag: "CT3-18",
        score: 0.5,
        max_score: 0.5,
        question: "How many subscribers does the project have on Reddit?",
        answer_id: 2,
        answer_name: ">1K",
        name: "Reddit",
        category: "community",
      },
      {
        id: 19,
        tag: "CT4-19",
        score: 0,
        max_score: 0.5,
        question:
          "For the subreddit, does it appear to have an active environment?",
        answer_id: 2,
        answer_name: "No",
        name: "Subreddit Activity",
        category: "community",
      },
      {
        id: 20,
        tag: "CT5-20",
        score: 0.5,
        max_score: 0.5,
        question: "How many subscribers does the project have on Telegram?",
        answer_id: 2,
        answer_name: ">1K",
        name: "Telegram",
        category: "community",
      },
      {
        id: 21,
        tag: "CT6-21",
        score: 0.5,
        max_score: 0.5,
        question: "Does their Telegram appear to have an active environment?",
        answer_id: 1,
        answer_name: "Yes",
        name: "Telegram Activity",
        category: "community",
      },
      {
        id: 22,
        tag: "CT7-22",
        score: 0.5,
        max_score: 1,
        question: "How many members does the project have on discord?",
        answer_id: 2,
        answer_name: "1000 to 10K",
        name: "Discord",
        category: "community",
      },
      {
        id: 23,
        tag: "CT8-23",
        score: 1,
        max_score: 1,
        question: "Does their Discord appear to have an active environment?",
        answer_id: 1,
        answer_name: "Yes",
        name: "Discord Activity",
        category: "community",
      },
      {
        id: 24,
        tag: "CT9-24",
        score: 0,
        max_score: 0.5,
        question: "How many holders does the token have?",
        answer_id: 1,
        answer_name: "<1K",
        name: "Hodlers",
        category: "community",
      },
      {
        id: 25,
        tag: "CT10-25",
        score: 0,
        max_score: 1,
        question:
          "Do they have any official partnerships with other established projects?",
        answer_id: 2,
        answer_name: "No",
        name: "Partnerships",
        category: "community",
      },
      {
        id: 26,
        tag: "CT11-26",
        score: 0.5,
        max_score: 0.5,
        question: "Do they have an official ambassador program?",
        answer_id: 1,
        answer_name: "Yes",
        name: "Ambassadors",
        category: "community",
      },
      {
        id: 27,
        tag: "CT12-27",
        score: 0,
        max_score: 0.5,
        question: "Does the project unfairly ban members from social media?",
        answer_id: 1,
        answer_name: "Yes",
        name: "Banishment",
        category: "community",
      },
      {
        id: 28,
        tag: "CT13-28",
        score: 0.5,
        max_score: 0.5,
        question:
          "Does the team respond to questions promptly and satisfactorily?",
        answer_id: 1,
        answer_name: "Yes",
        name: "Responsiveness",
        category: "community",
      },
    ],
    trading_metrics: "inapplicable",
  },
  notes: {
    answer: "Positive",
    textarea: "positive",
  },
  data: [
    {
      id: 0,
      answer: 2,
      textarea: "0",
      input: null,
      _id: "6418ef16b0017f719a2de6b8",
    },
    {
      id: 1,
      answer: 1,
      textarea: "1",
      input: null,
      _id: "6418ef16b0017f719a2de6b9",
    },
    {
      id: 2,
      answer: 1,
      textarea: "2",
      input: null,
      _id: "6418ef16b0017f719a2de6ba",
    },
    {
      id: 3,
      answer: 2,
      textarea: "3",
      input: null,
      _id: "6418ef16b0017f719a2de6bb",
    },
    {
      id: 4,
      answer: 1,
      textarea: "4",
      input: null,
      _id: "6418ef16b0017f719a2de6bc",
    },
    {
      id: 5,
      answer: 2,
      textarea: "5",
      input: null,
      _id: "6418ef16b0017f719a2de6bd",
    },
    {
      id: 6,
      answer: 1,
      textarea: "6",
      input: null,
      _id: "6418ef16b0017f719a2de6be",
    },
    {
      id: 7,
      answer: 2,
      textarea: "7",
      input: null,
      _id: "6418ef16b0017f719a2de6bf",
    },
    {
      id: 8,
      answer: 2,
      textarea: "8",
      input: null,
      _id: "6418ef16b0017f719a2de6c0",
    },
    {
      id: 9,
      answer: 2,
      textarea: "",
      input: [
        {
          labels: ["label1", "label2", "label3"],
          datasets: [
            {
              data: [49, 51, 30],
              backgroundColor: [
                "#27aeef",
                "#2ea78e",
                "#3bd0b1",
                "#6cecd4",
                "#ffcc5d",
                "#faae04",
                "#ea5545",
                "#fa6367",
                "#fdc1c2",
                "#8a7cf8",
                "#adb9ca",
                "#55c6e0",
                "#1967b3",
                "#b33dc6",
              ],
              hoverBackgroundColor: [
                "#27aeef",
                "#2ea78e",
                "#3bd0b1",
                "#6cecd4",
                "#ffcc5d",
                "#faae04",
                "#ea5545",
                "#fa6367",
                "#fdc1c2",
                "#8a7cf8",
                "#adb9ca",
                "#55c6e0",
                "#1967b3",
                "#b33dc6",
              ],
              borderWidth: 1,
            },
          ],
        },
      ],
      _id: "6418ef16b0017f719a2de6c1",
    },
    {
      id: 10,
      answer: 2,
      textarea: "10",
      input: null,
      _id: "6418ef16b0017f719a2de6c2",
    },
    {
      id: 11,
      answer: 2,
      textarea: "11",
      input: null,
      _id: "6418ef16b0017f719a2de6c3",
    },
    {
      id: 12,
      answer: 1,
      textarea: "12",
      input: null,
      _id: "6418ef16b0017f719a2de6c4",
    },
    {
      id: 13,
      answer: 1,
      textarea: "13",
      input: null,
      _id: "6418ef16b0017f719a2de6c5",
    },
    {
      id: 14,
      answer: 2,
      textarea: "14",
      input: null,
      _id: "6418ef16b0017f719a2de6c6",
    },
    {
      id: 15,
      answer: 2,
      textarea: "15",
      input: null,
      _id: "6418ef16b0017f719a2de6c7",
    },
    {
      id: 16,
      answer: 2,
      textarea: "",
      input: [16],
      _id: "6418ef16b0017f719a2de6c8",
    },
    {
      id: 17,
      answer: 2,
      textarea: "17",
      input: null,
      _id: "6418ef16b0017f719a2de6c9",
    },
    {
      id: 18,
      answer: 2,
      textarea: "",
      input: [18],
      _id: "6418ef16b0017f719a2de6ca",
    },
    {
      id: 19,
      answer: 2,
      textarea: "19",
      input: null,
      _id: "6418ef16b0017f719a2de6cb",
    },
    {
      id: 20,
      answer: 2,
      textarea: "",
      input: [10],
      _id: "6418ef16b0017f719a2de6cc",
    },
    {
      id: 21,
      answer: 1,
      textarea: "21",
      input: null,
      _id: "6418ef16b0017f719a2de6cd",
    },
    {
      id: 22,
      answer: 2,
      textarea: "",
      input: [22],
      _id: "6418ef16b0017f719a2de6ce",
    },
    {
      id: 23,
      answer: 1,
      textarea: "23",
      input: null,
      _id: "6418ef16b0017f719a2de6cf",
    },
    {
      id: 24,
      answer: 1,
      textarea: "24",
      input: null,
      _id: "6418ef16b0017f719a2de6d0",
    },
    {
      id: 25,
      answer: 2,
      textarea: "25",
      input: null,
      _id: "6418ef16b0017f719a2de6d1",
    },
    {
      id: 26,
      answer: 1,
      textarea: "26",
      input: null,
      _id: "6418ef16b0017f719a2de6d2",
    },
    {
      id: 27,
      answer: 1,
      textarea: "27",
      input: null,
      _id: "6418ef16b0017f719a2de6d3",
    },
    {
      id: 28,
      answer: 1,
      textarea: "28",
      input: null,
      _id: "6418ef16b0017f719a2de6d4",
    },
    {
      id: 29,
      answer: 0,
      textarea: "29",
      input: null,
      _id: "6418ef16b0017f719a2de6d5",
    },
    {
      id: 30,
      answer: 1,
      textarea: "30",
      input: null,
      _id: "6418ef16b0017f719a2de6d6",
    },
    {
      id: 31,
      answer: 1,
      textarea: "31",
      input: null,
      _id: "6418ef16b0017f719a2de6d7",
    },
    {
      id: 32,
      answer: 1,
      textarea: "32",
      input: null,
      _id: "6418ef16b0017f719a2de6d8",
    },
  ],
  pid: "REPORT_PID",
  createdAt: new Date(),
  updatedAt: new Date(),
  __v: 0,
  review: [],
};

export default fakeReport;
