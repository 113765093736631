import { updateField } from "vuex-map-fields";

const getAuditReportMutation = (state, payload) => {
  state.auditReportData = payload;
  state.controlValue.filename = payload.project.name;
};

const showReportLayout = (state) => {
  state.controlValue.showLayout = true;
};

const getAuditReportBase64Mutation = (state, payload) => {
  state.auditReportBase64 = payload;
};

export {
  updateField,
  getAuditReportBase64Mutation,
  getAuditReportMutation,
  showReportLayout,
};
