import authApi from "@/api/axios-api";

export const passwordReset = async (_, data) => {
  const { token, password, password_ } = data;

  try {
    const { data } = await authApi.post(`/api/users/password-reset/${token}`, {
      password,
      password_,
    });

    console.log("data", data);

    return { ok: true, response: "success" };
  } catch (error) {
    return { ok: false, response: error.response.data.errors };
  }
};
