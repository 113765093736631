import { createApp } from "vue"; 
import App from "./App.vue";
import { store } from "./store";
import { router } from "./router";

import PrimeVue from "primevue/config";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Divider from "primevue/divider";
import Skeleton from "primevue/skeleton";
import Chips from "primevue/chips";
import Chip from "primevue/chip";
import Avatar from "primevue/avatar";
import TabView from "primevue/tabview";
import Badge from "primevue/badge";
import Tooltip from "primevue/tooltip";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import Sidebar from "primevue/sidebar";
import AutoComplete from "primevue/autocomplete";
import MultiSelect from "primevue/multiselect";
import ProgressBar from "primevue/progressbar";
import BadgeDirective from "primevue/badgedirective";
import TabMenu from "primevue/tabmenu";
import Galleria from "primevue/galleria";
import Checkbox from "primevue/checkbox";
import Menu from "primevue/menu";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Chart from "primevue/chart";
import Timeline from "primevue/timeline";
import Ripple from "primevue/ripple";

import "/src/styles/theme.css";
import "/src/styles/query.css";

import "primeflex/primeflex.css";

const app = createApp(App);

app.use(store);

app.use(router);

app.use(PrimeVue, { ripple: true });

app.use(ConfirmationService);



/* eslint-disable */
app.component("Dropdown", Dropdown);
app.component("Button", Button);
app.component("Card", Card);
app.component("Calendar", Calendar);
app.component("InputText", InputText);
app.component("Password", Password);
app.component("Divider", Divider);
app.component("Skeleton", Skeleton);
app.component("Chip", Chip);
app.component("Chips", Chips);
app.component("Avatar", Avatar);
app.component("TabView", TabView);
app.component("Badge", Badge);
app.component("Dialog", Dialog);
app.component("Sidebar", Sidebar);
app.component("ProgressSpinner", ProgressSpinner);
app.component("AutoComplete", AutoComplete);
app.component("MultiSelect", MultiSelect);
app.component("ProgressBar", ProgressBar);
app.component("Menu", Menu);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Chart", Chart);
app.component("Timeline", Timeline);
app.component("TabMenu", TabMenu);
app.component("Galleria", Galleria);
app.component("Checkbox", Checkbox);


app.directive("Tooltip", Tooltip);
app.directive("badge", BadgeDirective);
app.directive("ripple", Ripple);

router.isReady().then(function () {
  app.mount("#app");
});
