import queryApi from "@/api/query-api";
import { gql } from "graphql-request";

const getAllProjects = async (_, variables) => {
  const query = gql`
    query getAllProjects($key: String, $page: Int, $filter: ProjectFilter) {
      getAllProjects(key: $key, page: $page, filter: $filter) {
        pid
        name
        logo_url {
          base
          path
        }
        description
        category
        tags
      }
    }
  `;

  try {
    const data = await queryApi.request(query, variables);

    const { getAllProjects } = data;

    return { ok: true, response: getAllProjects };
  } catch (error) {
    return { ok: false, response: error };
  }
};

const interactionTimeline = async (_, variables) => {
  const query = gql`
    query interactionTimeline($time: String) {
      interactionTimeline(time: $time) {
        pid

        title

        interaction_name

        upvote_count

        comment_count

        view_count

        user_data {
          username

          avatar
        }

        createdAt
      }
    }
  `;

  try {
    const data = await queryApi.request(query, variables);

    const { interactionTimeline } = data;

    return { ok: true, response: interactionTimeline };
  } catch (error) {
    return { ok: false, response: error };
  }
};

export { getAllProjects, interactionTimeline };
