<template>
  <main>
    <router-view />
  </main>
</template>

<script>
import entryAPI from "@/pages/entry/composable/entry-api";
import exploreAPI from "@/pages/explore/composable/explore-api";

export default {
  name: "App",
  setup() {
    const { currentUser } = entryAPI();

    const { userAuditInfo } = exploreAPI();

    currentUser()
      .then(() => userAuditInfo())
      .catch((e) => console.error(e));

    const themes = { 0: "theme-light", 1: "theme-dark" };

    const theme = localStorage.getItem("theme");

    if (theme === 2) {
      document.documentElement.className = themes[0];
    }

    if (!theme) {
      localStorage.setItem("theme", 0);
      document.documentElement.className = themes[0];
    } else {
      document.documentElement.className = themes[theme];
    }

    let platform =
      navigator?.userAgentData?.platform +
      navigator.userAgent +
      navigator?.platform || "unknown";

    let font;

    if (/mac|iphone|ipod|ipad/i.test(platform)) {
      font =
        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol";
    } else {
      font = "Inter, sans-serif";
    }

    document.body.style.fontFamily = font;

    document.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  },
};
</script>

<style>
@media (max-width: 575.98px) {
  #app::-webkit-scrollbar {
    width: 0px;
  }

  #app::-webkit-scrollbar-track {
    background-color: transparent;
    height: 0;
    width: 0;
  }

  #app::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  #app::-webkit-scrollbar-button {
    display: none;
  }
}

@media (max-width: 767.98px) {
  #app::-webkit-scrollbar {
    width: 0px;
  }
}

@media (max-width: 991.98px) {
  #app::-webkit-scrollbar {
    width: 0px;
  }
}

@media (max-width: 1199.98px) {
  #app::-webkit-scrollbar {
    width: 0px;
  }
}
</style>
