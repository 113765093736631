const feedLazyTimelineGetter = (state) => {
  return state.feedLazyTimeline;
};

const feedOverlayGetter = (state) => {
  return state.feedOverlay;
};

const feedPostGetter = (state) => {
  return state.feedPost;
};

const feedScrollPositionGetter = (state) => {
  return state.feedScrollPosition;
};

const getterEditorData = (state) => {
  return state.editorData;
};


const editorChannelGetter = (state) => {
  return state.feedEditorChannel;
};

export {
  editorChannelGetter,
  getterEditorData,
  feedLazyTimelineGetter,
  feedOverlayGetter,
  feedPostGetter,
  feedScrollPositionGetter,
};
