import queryApi from "@/api/query-api";
import { gql } from "graphql-request";
import axiosApi from "@/api/axios-api";
import dyorApi from "@/api/dyor-api";
////////////////////////////////////////////////

const displayEditor = async ({ commit }, params) => {
  commit("displayEditor", params);
};

//////////////////////////////////////////////////////////////////////////
const getProject = async ({ commit }, variables) => {
  const query = gql`
    query ExplorePage($projectName: String!) {
      explorePage(projectName: $projectName) {
        pid
        name
        category
        description
        logo_url {
          base
          path
        }
        repository {
          name
          link
        }
        whitepaper
        website
        community {
          reddit
          telegram
          instagram
          youtube
          discord
          medium
          facebook
          twitter
        }
        tags
        createdAt
      }
    }
  `;

  try {
    const { explorePage } = await queryApi.request(query, variables);

    commit("currentProjectMutation", explorePage);

    return { ok: true, response: explorePage };
  } catch (error) {
    return { ok: false, response: error };
  }
};

//////////////////////////////////////////////////////////////////////////
const getExploreExtras = async ({ commit }) => {
  const query = gql`
    query ProjectStats {
      projectStats {
        related {
          name
          logo_url {
            base
            path
          }
          category
        }

        tags {
          tag

          count
        }
      }
    }
  `;

  try {
    const { projectStats } = await queryApi.request(query);

    commit("getExploreExtrasMutation", projectStats);

    return { ok: true, response: projectStats };
  } catch (error) {
    throw { ok: false, response: error };
  }
};
//////////////////////////////////////////////////////////////////////////
const getInteraction = async ({ commit }, variables) => {
  const query = gql`
    query Query($name: String!, $page: Int) {
      interactionTrends(name: $name) {
        pid

        interaction_pid

        interaction_name

        preview_

        youtube

        image

        view_count

        comment_count

        upvote_count

        user_upvote

        user_data {
          username

          avatar
        }

        createdAt
      }

      interactionByName(name: $name, page: $page) {
        pid

        post_count

        bull_count

        bear_count

        user_bull

        user_bear

        posts {
          pid

        interaction_pid

        interaction_name

        preview_

        youtube

        image

        view_count

        comment_count

        upvote_count

        user_upvote

        user_data {
          username

          avatar
        }

        createdAt
        }
      }
    }
  `;

  try {
    const response = await queryApi.request(query, variables);

    const { interactionByName } = response;

    const scheme = {
      pid: interactionByName.pid,
      post_count: interactionByName.post_count,
      audit_count: 0,
      bull_count: interactionByName.bull_count,
      bear_count: interactionByName.bear_count,
      user_bull: interactionByName.user_bull,
      user_bear: interactionByName.user_bear,
    };

    commit("getInteractionMutation", scheme);

    return { ok: true, response: response };
  } catch (error) {
    throw { ok: false, response: error };
  }
};

/////////////////////////////////////////////////////////////////////
const createBear = async ({ commit }, { interaction_pid }) => {
  try {
    const response = await axiosApi.post("/api/interactions/create-bear", {
      interaction_pid: interaction_pid,
    });

    commit("createBear");

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

/////////////////////////////////////////////////////////////////////
const deleteBear = async ({ commit }, { interaction_pid }) => {
  try {
    const response = await axiosApi.post("/api/interactions/delete-bear", {
      interaction_pid: interaction_pid,
    });

    commit("deleteBear");

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

/////////////////////////////////////////////////////////////////////
const createPost = async (
  { commit },
  { user_data, interaction_pid, title, tag, content }
) => {
  try {
    const response = await axiosApi.post("/api/interactions/create-post", {
      interaction_pid: interaction_pid,
      title: title,
      tag: tag,
      content: content,
    });

    const { payload } = response.data;

    const fakePost = {
      pid: payload.pid,
      interaction_pid: payload.interaction_pid,
      interaction_name: payload.interaction_name,
      content: payload.content,
      view_count: 1,
      comment_count: 0,
      upvote_count: 0,
      user_upvote: 0,
      comments: [],
      user_data: user_data,
      createdAt: payload.createdAt,
    };

    commit("createPostMutation", fakePost);

    commit("pushLocalPostMutation", fakePost);

    return { ok: true, response: fakePost };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

/////////////////////////////////////////////////////////////////////
const createBull = async ({ commit }, { interaction_pid }) => {
  try {
    const response = await axiosApi.post("/api/interactions/create-bull", {
      interaction_pid: interaction_pid,
    });

    commit("createBull");

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

/////////////////////////////////////////////////////////////////////
const deleteBull = async ({ commit }, { interaction_pid }) => {
  try {
    const response = await axiosApi.post("/api/interactions/delete-bull", {
      interaction_pid: interaction_pid,
    });

    commit("deleteBull");

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

////////////////////////////////////////////////////////
const createUpvote = async (_, { post_pid }) => {
  try {
    const response = await axiosApi.post("/api/interactions/create-upvote", {
      post_pid: post_pid,
    });
    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

/////////////////////////////////////////////////////////////////////
// eslint-disable-next-line
const deleteUpvote = async ({ commit }, { post_pid }) => {
  try {
    const response = await axiosApi.post("/api/interactions/delete-upvote", {
      post_pid: post_pid,
    });

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

/////////////////////////////////////////////////////////////////////

const getTimeline = async ({ commit }, params) => {
  console.log(params);
  try {
    const response = await axiosApi.post("/api/interactions/get-timeline", {
      interaction_pid: params,
    });

    const { posts, comments, replies } = response.data;

    commit("userPostsMutation", posts);

    commit("userCommentsMutation", comments);

    commit("userRepliesMutation", replies);

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};
/////////////////////////////////////////////////////////////////////

const getAllReports = async ({ commit }, project_name) => {
  try {
    const response = await axiosApi.get(
      `/api/audits/get-all-reports/${project_name}`
    );

    commit("getAllReportsMutation", response.data);

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error };
  }
};
/////////////////////////////////////////////////////////////////////

const getDyorReports = async ({ commit }, projectName) => {
  try {
    const response = await dyorApi.get(`/v1/report/searchName/${projectName}`);

    commit("getDyorReportsMutation", response.data);

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};
/////////////////////////////////////////////////////////////////////
const saveLocalPost = ({ commit }, post) => {
  commit("pushLocalPostMutation", post);
};

/////////////////////////////////////////////////////////////////////

const createPrevote = async ({ commit }, { project_pid }) => {
  try {
    const response = await axiosApi.post("/api/audits/create-prevote", {
      project_pid: project_pid,
    });

    commit("createPrevoteMutation");

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

/////////////////////////////////////////////////////////////////////
const userAuditInfo = async ({ commit }) => {
  try {
    const response = await axiosApi.get("/api/audits/get-user-information");

    commit("userAuditInfoMutation", response.data);

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

/////////////////////////////////////////////////////////////////////
export {
  getProject,
  getInteraction,
  createPost,
  createUpvote,
  deleteUpvote,
  displayEditor,
  saveLocalPost,
  userAuditInfo,
  getDyorReports,
  getAllReports,
  createPrevote,
  getExploreExtras,
  deleteBull,
  createBull,
  createBear,
  deleteBear,
  getTimeline,
};
