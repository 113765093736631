const currentProjectMutation = (state, project) => {
  state.current_project = project;
};

const getDyorReportsMutation = (state, data) => {
  if(!data.length){
    return state.community_reports = []
  }
  state.community_reports = data.map((e) => {
    return {
      id: e.id,
      percentage: e.total_percentage,
      score: e.total_score,
      author: e.author,
      date: new Date(e.date).toISOString().split("T")[0],
    };
  });
};

const getExploreExtrasMutation = (state, data) => {
  state.explore_extras = data;
};

const createPrevoteMutation = (state) => {
  state.user_audit_info.pre_vote = true;
  state.user_audit_info.button = {
    locked: true,
    icon: "pi pi-check-circle",
  };
};

const getInteractionMutation = (state, data) => {
  state.current_interaction = data;
};

const userPostsMutation = (state, data) => {
  state.user_posts = data;
};

const userAuditInfoMutation = (state, data) => {
  state.user_audit_info = data;
};

const getAllReportsMutation = (state, data) => {
  state.all_reports = data.map((e) => {
    return {
      pid: e.pid,
      round: e.round,
      group: e.group_name,
      score: e.total_score,
      percentage: e.total_percentage,
      date: new Date(e.createdAt).toISOString().split("T")[0],
    };
  });
};

const userCommentsMutation = (state, data) => {
  state.user_comments = data;
};

const userRepliesMutation = (state, data) => {
  state.user_replies = data;
};

const displayEditor = (state, params) => {
  state.display_editor = params;
};

const currentAudit = (state, data) => {
  state.current_audit = data;
};

const createBull = (state) => {
  state.current_interaction.bull_count++;
  state.current_interaction.user_bull++;
};

const deleteBull = (state) => {
  state.current_interaction.bull_count--;
  state.current_interaction.user_bull--;
};

const createBear = (state) => {
  state.current_interaction.bear_count++;
  state.current_interaction.user_bear++;
};

const deleteBear = (state) => {
  state.current_interaction.bear_count--;
  state.current_interaction.user_bear--;
};

const createPostMutation = (state, payload) => {
  state.last_post = payload;
};

const pushLocalPostMutation = (state, payload) => {
  state.user_posts.unshift(payload);
};

export {
  createBull,
  deleteBull,
  createPostMutation,
  getDyorReportsMutation,
  displayEditor,
  currentProjectMutation,
  userPostsMutation,
  pushLocalPostMutation,
  currentAudit,
  getExploreExtrasMutation,
  getInteractionMutation,
  createBear,
  getAllReportsMutation,
  userAuditInfoMutation,
  deleteBear,
  userCommentsMutation,
  createPrevoteMutation,
  userRepliesMutation
};
