
export default () => ({
  auditReportData: null,
  auditReportBase64: "",
  controlValue: {
    showLayout: false,
    floatLayout: true,
    enableDownload: true,
    previewModal: false,
    paginateElementsByHeight: 1100,
    manualPagination: true,
    filename: "",
    pdfQuality: 2,
    pdfFormat: "a4",
    pdfOrientation: "portrait",
    pdfContentWidth: "800px",
  },
});
