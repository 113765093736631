import fakeReport from "@/pages/utils/fake-report";

export default () => ({
  last_query: null,
  roundInformation: {
    round: {
      report_deadline: null,
      round: "N/A",
      budget: 0,
      reward: 0,
      type: "public",
      status: "N/A",
      selection_deadline: "GTM_DATE",
      governance_deadline: "GTM_DATE",
      auditing_deadline: "GTM_DATE",
      selection_projects: [],
      governance_projects: [],
      pid: "ROUND_PID",
      epoch: "2023",
      createdAt: "GTM_DATE",
      prevotes: 0,
      daovotes: 0,
      subscriptions: 0,
      reviews: [fakeReport],
      groups: [],
    },
    auditors: 2,
    projects: [],
  },
});
