import { getField } from "vuex-map-fields";

const getterAuditReport = (state) => {
  return state.auditReportData;
};

const getterAuditReportBase64 = (state) => {
  return state.auditReportBase64.base64;
};

export { getField, getterAuditReportBase64, getterAuditReport };
