import { createStore } from "vuex";
import { entry } from "../pages/entry/store";
import { explore } from "../pages/explore/store";
import { projects } from "../pages/projects/store";
import { reset } from "../pages/reset/store";
import { verify } from "../pages/verify/store";
import { governance } from "../pages/governance/store";
import { report } from "../pages/report/store";
import { feed } from "@/pages/feed/store";
const store = createStore({
  modules: {
    feed,
    projects,
    explore,
    entry,
    verify,
    reset,
    governance,
    report,
  },
});

export { store };
