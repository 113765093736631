import axiosApi from "@/api/axios-api";

const signUp = async (_, user) => {
  const { email, password, username } = user;

  try {
    const response = await axiosApi.post("/api/users/signup", {
      email,
      password,
      username,
    });

    return { ok: true, message: response.data };
  } catch (error) {
    throw { ok: false, message: error.response.data };
  }
};

const signIn = async ({ commit }, user) => {
  const { email, password } = user;

  try {
    const response = await axiosApi.post("/api/users/signin", {
      email,
      password,
    });

    commit("signInMutation", response.data);

    return { ok: true, message: response.data };
  } catch (error) {
    throw { ok: false, message: error.response.data };
  }
};

const signOut = async ({ commit }) => {
  try {
    const response = await axiosApi.post("/api/users/signout");

    commit("signOutMutation");

    return { ok: true, message: response.data };
  } catch (error) {
    throw { ok: false, message: error.response.data };
  }
};

const recovery = async (_, email) => {
  try {
    const response = await axiosApi.post("/api/users/recovery", {
      email,
    });
    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

const resendEmail = async (_, user) => {
  const { email, password } = user;

  try {
    const response = await axiosApi.post("/api/users/resend-email", {
      email,
      password,
    });

    return { ok: true, message: response.data };
  } catch (error) {
    throw { ok: false, message: error.response.data };
  }
};

const currentUser = async ({ commit }) => {
  try {
    const response = await axiosApi.get("/api/users/current-user");

    const { currentUser } = response.data;
    currentUser
      ? commit("currentUserMutation", currentUser)
      : commit("signOutMutation");

    return { success: true, message: response.data };
  } catch (error) {
    throw { success: false, message: error.response.data };
  }
};

export { signUp, signIn, recovery, resendEmail, signOut, currentUser };
