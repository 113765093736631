import queryApi from "@/api/query-api";
import { gql } from "graphql-request";
import axiosApi from "@/api/axios-api";

const feedLazyTimeline = async ({ commit }) => {
  const query = gql`
    query feedLazyTimeline {
      feedLazyTimeline {
        pid

        title

        upvote_count

        comment_count

        user_data {
          username

          avatar
        }

        interaction_name

        createdAt
      }
    }
  `;

  try {
    const data = await queryApi.request(query);

    const { feedLazyTimeline } = data;

    commit("feedLazyTimelineCommit", feedLazyTimeline);

    return { ok: true, response: feedLazyTimeline };
  } catch (error) {
    throw { ok: false, response: error };
  }
};

const feedTimeline = async (_, variables) => {
  const query = gql`
    query feedTimeline($page: Int) {
      feedTimeline(page: $page) {
        pid

        interaction_pid

        interaction_name

        preview_

        youtube

        image

        view_count

        comment_count

        upvote_count

        user_upvote

        user_data {
          username

          avatar
        }

        createdAt
      }
    }
  `;

  try {
    const { feedTimeline } = await queryApi.request(query, variables);

    return { ok: true, response: feedTimeline };
  } catch (error) {
    throw { ok: false, response: error };
  }
};

const feedPost = async ({ commit }, variables) => {
  const query = gql`
    query feedPost($pid: String!, $page: Int) {
      feedPost(pid: $pid, page: $page) {
        pid

        interaction_pid

        interaction_name

        content

        view_count

        comment_count

        upvote_count

        user_upvote

        comments {
          pid

          content

          user_data {
            pid

            username

            avatar
          }

          replies_count

          createdAt

          updatedAt
        }

        user_data {
          pid

          username

          avatar
        }

        createdAt
      }
    }
  `;

  try {
    commit("cleanFeedPostCommit");

    const response = await queryApi.request(query, variables);

    commit("feedPostCommit", response.feedPost);

    return { ok: true, response: response.feedPost };
  } catch (error) {
    throw { ok: false, response: error };
  }
};

const getReplies = async (_, variables) => {
  const query = gql`
    query replyByPid($pid: String!, $page: Int) {
      replyByPid(pid: $pid, page: $page) {
        pid

        content

        user_data {
          pid

          username

          avatar
        }

        createdAt

        updatedAt
      }
    }
  `;

  try {
    const { replyByPid } = await queryApi.request(query, variables);

    return { ok: true, response: replyByPid };
  } catch (error) {
    throw { ok: false, response: error };
  }
};

const feedOverlay = ({ commit }, params) => {
  commit("feedOverlayCommit", params);
};

const cleanFeedPost = ({ commit }, params) => {
  commit("cleanFeedPostCommit", params);
};

const deleteComment = async (_, { comment_pid }) => {
  try {
    const response = await axiosApi.post("/api/interactions/delete-comment", {
      comment_pid: comment_pid,
    });

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

const createComment = async (_, { post_pid, content }) => {
  try {
    const response = await axiosApi.post("/api/interactions/create-comment", {
      post_pid,
      content,
    });

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

const deletePost = async (_, post_pid) => {
  try {
    const response = await axiosApi.post("/api/interactions/delete-post", {
      post_pid,
    });

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

const createReply = async (_, { comment_pid, content }) => {
  try {
    const response = await axiosApi.post("/api/interactions/create-reply", {
      comment_pid,
      content,
    });

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

const getInteractions = async ({ commit }) => {
  try {
    const response = await axiosApi.get("/api/interactions/get-interactions");

    commit("getInteractionsCommit", response.data);

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

const changeEditorChannel = async ({ commit }, params) => {
  commit("changeEditorChannelCommit", params);
};

const deleteReply = async (_, { reply_pid }) => {
  try {
    const response = await axiosApi.post("/api/interactions/delete-reply", {
      reply_pid: reply_pid,
    });

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

const createPost = async ({ commit }, params) => {
  try {
    const response = await axiosApi.post("/api/interactions/create-post", {
      interaction_pid: params.interaction_pid,
      title: params.title,
      tag: params.tag,
      content: params.content,
    });

    const { payload } = response.data;

    const postScheme = {
      pid: payload.pid,
      interaction_pid: payload.interaction_pid,
      interaction_name: payload.interaction_name,
      content: payload.content,
      view_count: 1,
      comment_count: 0,
      upvote_count: 0,
      user_upvote: 0,
      comments: [],
      user_data: params.user_data,
      createdAt: payload.createdAt,
    };

    commit("feedPostCommit", postScheme);

    return { ok: true, response: postScheme };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};

const getImages = async (_, params) => {
  try {
    const response = await axiosApi.post(
      "/api/interactions/get-images",
      params
    );

    return { ok: true, response: response.data };
  } catch (error) {
    throw { ok: false, response: error.response.data };
  }
};
export {
  getImages,
  feedLazyTimeline,
  changeEditorChannel,
  feedTimeline,
  feedOverlay,
  feedPost,
  deleteComment,
  createComment,
  deletePost,
  getReplies,
  createReply,
  deleteReply,
  createPost,
  getInteractions,
  cleanFeedPost,
};
