import { createRouter, createWebHistory } from "vue-router";

import entryRoute from "@/pages/entry/router";
import resetRoute from "@/pages/reset/router";
import verifyRoute from "@/pages/verify/router";
import exploreRoute from "@/pages/explore/router";
import governanceRoute from "@/pages/governance/router";
import reportRoute from "@/pages/report/router";
import feedRoute from "@/pages/feed/router";
import projectsRoute from "@/pages/projects/router";
import landRoute from "@/pages/land/router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      ...landRoute
    },
    {
      path: "/home/:path?/:sub?/:name?/:pid?",
      ...feedRoute,
    },
    {
      path: "/projects",
      ...projectsRoute,
    },
    {
      path: "/entry",
      ...entryRoute,
    },
    {
      path: "/password-reset/:token",
      ...resetRoute,
    },
    {
      path: "/verify-email/:token?",
      ...verifyRoute,
    },
    { path: "/explore/:name", ...exploreRoute },

    {
      path: "/governance",
      ...governanceRoute,
    },

    {
      path: "/report/:id",
      ...reportRoute,
    },

    { path: "/:notFound(.*)", redirect: "/" },
  ],
});

export { router };
